import { createAction, props } from '@ngrx/store';
import { CounterUser, UserRole } from '@models/user';
import { AssignedVehicleDto, Auth0UserDetailDto, HasDealerDto } from '@services/api.service';
import { UserPreferences } from '@shared/_types/UserPreferences';
import { Auth0AgrirouterAccountDto } from '@services/sdf-agrirouter-api.service';

export const resetState = createAction(
  'resetState',
);
export const setAgriRouterInfo = createAction(
  'setAgriRouterInfo',
  props<{ value: Auth0AgrirouterAccountDto[] }>(),
);

export const setNextAccountsPage = createAction(
  'setNextAccountsPage',
  props<{ value: number }>(),
);

export const setNextVehiclePage = createAction(
  'setNextVehiclePage',
  props<{ value: number }>(),
);

export const setNextChildrenPage = createAction(
  'setNextChildrenPage',
  props<{ value: number }>(),
);

export const setNextParentPage = createAction(
  'setNextParentPage',
  props<{ value: number }>(),
);

export const setTractors = createAction(
  'setTractors',
  props<{ value: AssignedVehicleDto[] }>(),
);

export const setRolesCanCreate = createAction(
  'setRolesCanCreate',
  props<{ value: UserRole[] }>(),
);

export const setChildren = createAction(
  'setChildren',
  props<{ value: Auth0UserDetailDto[] }>(),
);

export const setCounters = createAction(
  'setCounters',
  props<{ value: CounterUser }>(),
);

export const setFactories = createAction(
  'setFactories',
  props<{ value: Auth0UserDetailDto[] }>(),
);

export const setRadicals = createAction(
  'setRadicals',
  props<{ value: string[] }>(),
);

export const setTractorsCounter = createAction(
  'setTractorsCounter',
  props<{ value: number }>(),
);

export const setHasDealer = createAction(
  'setHasDealer',
  props<{ value: HasDealerDto }>(),
);

export const setDataMgmtLoaded = createAction(
  'setDataMgmtLoaded',
  props<{ value: boolean }>(),
);

export const setFleetMgmtLoaded = createAction(
  'setFleetMgmtLoaded',
  props<{ value: boolean }>(),
);

export const setDealerMgmtLoaded = createAction(
  'setDealerMgmtLoaded',
  props<{ value: boolean }>(),
);

export const setUserMgmtLoaded = createAction(
  'setUserMgmtLoaded',
  props<{ value: boolean }>(),
);

export const setUser = createAction(
  'setUser',
  props<{ value: any }>(),
);

export const setUserPreferences = createAction(
  'setUserPreferences',
  props<{ value: UserPreferences, skipEffect?: boolean }>(),
);
